<template>
  <div class="hero">
    <div id="navbar" :class="{ backgroundi: show }">
      <div class="nav-brand" data-aos="zoom-in" data-aos-duration="1500">
        <!-- <h2>DS-Logistic</h2> -->
        <router-link to="/" data-aos="zoom-in" data-aos-duration="1500">
          <img style="height: 50px" src="../../assets/new-logo.svg" alt="" />
        </router-link>
        <b-icon-list
          scale="2"
          class="fa fa-navicon"
          v-on:click="display"
        ></b-icon-list>
      </div>

      <div class="bar-items" :class="{ hide: !show }">
        <div id="menu" data-aos="zoom-in" data-aos-duration="1500">
          <RouterLink to="/">{{$t("Home")}}</RouterLink>

          <RouterLink to="/services">{{$t("Services")}}</RouterLink>

          <RouterLink to="/aboutUs">{{$t("About")}}</RouterLink>

          <RouterLink to="/contact">{{$t("Contact")}}</RouterLink>
          <div class="language-selector">
            <b-dropdown id="dropdown" drop color="black">
 						 <template #button-content>
    						<b-icon icon="globe" style="width:25px; height:30px;"></b-icon>
  						</template>
              <b-dropdown-item @click="changeL('de')">Deutsch</b-dropdown-item>
        <b-dropdown-item @click="changeL('en')">English</b-dropdown-item>
        <b-dropdown-item @click="changeL('fr')">French</b-dropdown-item>
        <!-- <b-dropdown-item @click="changeL('it')">Italian</b-dropdown-item>
        <b-dropdown-item @click="changeL('fr')">French</b-dropdown-item> -->
        </b-dropdown>
      </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="content cards">
        <h1 data-aos="zoom-in" data-aos-duration="1500" >Anytime, anywhere, Logistik is everywhere!</h1>
        <!-- <p>Ihr Partner Nummer 1 fur Dienstlestungen</p> -->
        <!-- <div class="button">
          <button>See More</button>
        </div> -->
      </div>
      <!-- <div class="containerSection">
        <div class="containerr">
          <div class="card">
            <div class="img">
              <img src="../../assets/11632.jpg" alt="" />
            </div>
            <div>
              <div class="infoo">
                <div class="info">
                  <div class="name">
                    <h3>Modern Chair</h3>
                    <p>Lorem Ipsum</p>
                  </div>
                  <div class="price">
                    <span>1555$</span>
                    <p>788.5$</p>
                  </div>
                </div>
              </div>
              <div class="addToCartInfo">
                <div class="addToCart">
                  <b-button class="addToCartButton"> Add to cart </b-button>
                  <b-icon-heart scale="1.5"></b-icon-heart>
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="img">
              <img src="../../assets/11632.jpg" alt="" />
            </div>
            <div>
              <div class="infoo">
                <div class="info">
                  <div class="name">
                    <h3>Modern Chair</h3>
                    <p>Lorem Ipsum</p>
                  </div>
                  <div class="price">
                    <span>1555$</span>
                    <p>788.5$</p>
                  </div>
                </div>
              </div>
              <div class="addToCartInfo">
                <div class="addToCart">
                  <b-button class="addToCartButton"> Add to cart </b-button>
                  <b-icon-heart scale="1.5"></b-icon-heart>
                </div>
              </div>
            </div>
          </div>
          <div class="card card3">
            <div class="img">
              <img src="../../assets/11632.jpg" alt="" />
            </div>
            <div>
              <div class="infoo">
                <div class="info">
                  <div class="name">
                    <h3>Modern Chair</h3>
                    <p>Lorem Ipsum</p>
                  </div>
                  <div class="price">
                    <span>1555$</span>
                    <p>788.5$</p>
                  </div>
                </div>
              </div>
              <div class="addToCartInfo">
                <div class="addToCart">
                  <b-button class="addToCartButton"> Add to cart </b-button>
                  <b-icon-heart scale="1.5"></b-icon-heart>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      show: false
    };
  },
  computed: {
    ...mapGetters(["getLanguage"]),
    language() {
      if (this.getLanguage === "en") {
        return "English";
      } else if (this.getLanguage === "de") {
        return "Deutsch";
      } else if (this.getLanguage === "it") {
        return "Italian";
      } else if (this.getLanguage === "fr") {
        return "French";
      }
      return "Shqip";
    },
  },
  methods: {
    ...mapActions(["changeLanguage"]),

    changeL(lang) {
      this.changeLanguage(lang);
      if (this.$i18n.locale !== lang) {
        this.$i18n.locale = lang;
      }
    },

    display() {
      this.show = !this.show;
    },
  },
};
</script>



<style scoped >

@import "../../assets/style/service.scss";
.cards{
  margin-top: -60px;
}
.fa.fa-navicon {
    display: none;
  }
.nav {
  /* display: flex;
  justify-content: space-between; */
}

.woody {
  margin-right: 25%;
}
.logo {
  padding-right: 35%;
}

.nav {
  padding-left: 18%;
}
.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-content: center;
  margin-top: 371px;
}
.content {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font: normal normal bold 61px/81px Italic;
  /* height: 0px; */
}

.content h1 {
  /* margin-top: 15px; */
  /* height: 81px; */
  /* UI Properties */
  text-align: left;
  /* font: normal normal light 61px/81px Poppins; */
  letter-spacing: 5px;
  color: #ffffff;
  opacity: 1;
  display: flex;
  justify-content: center;
}

.card {
  height: 392px;
  width: 319px;
  background-color: bisque;
  display: flex;
  justify-content: space-evenly;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 17px 45px #00000029;
  border-radius: 20px;
  opacity: 1;
  flex-direction: column;
}

.card img {
  height: 205px;
  width: 205px;
}

.img {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.containerr {
  margin-top: 65px;
  margin-bottom: 47px;
  display: flex;
  justify-content: space-around;
  width: 1121px;
}
.containerSection {
  display: flex;
  justify-content: center;
}
.info {
  display: flex;
  justify-content: space-between;
  width: 80%;
}

.name h3 {
  text-align: left;
  font-size: 24px;
  font: normal normal medium 24px/32px Poppins;
  letter-spacing: -0.96px;
  color: #141414;
  opacity: 1;
}

.price p {
  width: 75px;
  height: 32px;
  /* UI Properties */
  text-align: left;
  font: normal normal 900 24px/32px Poppins;
  letter-spacing: -0.96px;
  color: #141414;
  opacity: 1;
}
.price {
  display: flex;
  flex-direction: column;
}
.price span {
  text-decoration: line-through;
  text-align: end;
  font-size: 14px;
}
.name p {
  text-align: left;
  font: 12px/16px Poppins;
  letter-spacing: -0.48px;
  color: #141414;
  opacity: 1;
}
.infoo {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.addToCart {
  display: flex;
  justify-content: space-between;
  width: 80%;
  align-items: center;
}

.addToCartButton {
  width: 156px;
  height: 30px;
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 12px;
  /* UI Properties */
  background: #008eff 0% 0% no-repeat padding-box;
  box-shadow: 0px 7px 5px #14141408;
  border-radius: 6px;
  opacity: 1;
}

.addToCartInfo {
  display: flex;
  justify-content: center;
  margin-top: 5%;
}

.content p {
  width: 100%;
  height: 33px;
  /* UI Properties */
  text-align: left;
  font: normal normal normal 25px/33px Poppins;
  letter-spacing: -1.25px;
  color: #ffffff;
  opacity: 1;
  display: flex;
  justify-content: center;
}
.seeMoreButton {
  width: 297px;
  height: 53px;
  /* UI Properties */
  background: #008eff 0% 0% no-repeat padding-box;
  opacity: 1;
  color: white;
  border: none;
  margin-top: 81px;
  display: flex;
  justify-content: center;
  /* UI Properties */
  text-align: center;
  font: normal normal bold 19px/26px Poppins;
  letter-spacing: 0.38px;
  color: #ffffff;
  opacity: 1;
}
.hero {
  width: 100%;
  height: 838px;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  background-position: 50% 100%;
  text-align: center;
  background-image: url(../../assets/imageh.png);
  object-fit: contain;
  /* background: url(../../assets/11632.jpg); */
}
.hero::before {
  background-color: wheat;
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: -1;
}

.button button {
  top: 562px;
  left: 812px;
  width: 297px;
  height: 53px;
  margin-top: 81px;
  /* UI Properties */
  background: #008eff 0% 0% no-repeat padding-box;
  opacity: 1;
  color: white;
  border: none;
}

nav {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-top: 50px;
  height: 21px;
  /* padding-left: 10%;
  padding-right: 10%; */
}

nav ul li {
  list-style-type: none;
  display: inline-block;
  color: white;
  padding: 10px 20px;
}

nav ul li a {
  color: white !important;
  text-decoration: none;
  font-weight: bold;
}
@media screen and (max-width: 1440px) {
  .content {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font: normal normal bold 40px/60px Italic;
  /* height: 0px; */
}
  .content h1 {
    /* width: 533px;
    height: 38px; */
    /* UI Properties */
    text-align: center;
    /* font: normal normal bold 40px/60px Poppins; */
    letter-spacing: 5px;
    color: #ffffff;
    opacity: 1;
  }

}

@media screen and (max-width: 1200px) {

  .content h1 {
    /* width: 533px;
    height: 38px; */
    /* UI Properties */
    text-align: center;
    /* font: normal normal bold 35px/60px Poppins; */
    letter-spacing: 4px;
    color: #ffffff;
    opacity: 1;
    font-size: 30px;
  }
  .content {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font: normal normal bold 35px/60px Italic;

  /* height: 0px; */
}
  .card {
    height: 390px;
    width: 280px;
    background-color: bisque;
    display: flex;
    justify-content: space-around;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 17px 45px #00000029;
    border-radius: 20px;
    opacity: 1;
    flex-direction: column;
  }
}

@media screen and (max-width: 1000px) {
  .content {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font: normal normal bold 30px/40px Italic;


  /* height: 0px; */
}
  .content h1 {
    /* width: 533px;
    height: 38px; */
    /* UI Properties */
    text-align: center;
    /* font: normal normal bold 30px/40px Poppins; */
    letter-spacing: 1.16px;
    color: #ffffff;
    opacity: 1;
  }
}
@media screen and (max-width: 991px) {

  .content h1 {
    /* width: 533px;
    height: 38px; */
    /* UI Properties */
    text-align: center;
    /* font: normal normal bold 35px/60px Poppins; */
    letter-spacing: 1px;
    color: #ffffff;
    opacity: 1;
    font-size: 30px;
  }
  .content {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font: normal normal bold 35px/60px Italic;

  /* height: 0px; */
}
  .card {
    height: 389px;
    width: 220px;
    background-color: bisque;
    display: flex;
    justify-content: space-around;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 17px 45px #00000029;
    border-radius: 20px;
    opacity: 1;
    flex-direction: column;
  }

  .card img {
    height: 170px;
    width: 180px;
  }
  .addToCartButton {
    width: 136px;
  }
}
/* @media screen and (max-width: 768px) {
  .content {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font: normal normal bold 22.5px/40px Italic;




}
  .content h1[data-v-619d7242] {
    text-align: center;

    letter-spacing: 1px;
    color: #ffffff;
    opacity: 1;
    font-size: 22px;
}
  .card3 {
    display: none !important;
  }
  .card {
    display: flex;
    justify-content: space-around;
  }
  ul {
    text-align: center;
    background-color: black;
  }
} */
@media (max-width: 780px) {
  .content {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font: normal normal bold 20px/40px Italic;



  /* height: 0px; */
}
  .content h1[data-v-619d7242] {
    text-align: center;
    /* font: normal normal bold 20px/40px Poppins; */
    letter-spacing: 1px;
    color: #ffffff;
    opacity: 1;
    font-size: 22px;
}
  #navbar {
    flex-direction: column;
  }
  .backgroundi {
    background-color: rgb(99, 99, 99) !important;
  }
  .backroundi {
    background: cornflowerblue;
  }
  .fa {
    display: block;
    padding: 0 25px;
    position: absolute;
    right: 0;
    color: #fff;
    font-size: 30px;
    cursor: pointer;
    margin-top: 30px;
  }
  .fa:hover {
    transform: scale(1);
  }

  #menu {
    flex-direction: column;
    padding: 5px 0;
  }

  a {
    text-align: center;
    width: 100%;
    box-sizing: border-box;
    padding: 15px;
  }

  .hide {
    display: none;
  }

  .nav-brand {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
     /* padding: 15px 0px; */
  }

  .b-icon.bi {
    display: inline-block;
  }
}
@media screen and (max-width: 490px) {
  .content {
    text-align: center;
    font: normal normal bold 17px/40px Italic;
    letter-spacing: 1.16px;
    color: #ffffff;
    opacity: 1;

}
  .content h1[data-v-619d7242] {
    text-align: center;
    /* font: normal normal bold 17px/40px Poppins; */
    letter-spacing: 1.16px;
    color: #ffffff;
    opacity: 1;
    font-size: 21px;
}
  .card3 {
    display: none !important;
  }
  .card {
    display: flex;
    justify-content: space-around;
    width: 170px;
    height: 310px;
  }

  .card img {
    width: 130px;
    height: 115px;
  }

  .name h3 {
    font-size: 13px;
  }
  .name p {
    font-size: 11px;
  }

  .price p {
    font-size: 15px;
    text-align: end;
  }

  .price span {
    font-size: 12px;
  }

  .addToCartButton {
    width: 90px;
  }

  .content p {
    text-align: center;
  }
  ul {
    text-align: center;
    background-color: black;
  }
}

body {
  margin: 0;
  font-family: "Lato", sans-serif;
}

h2 {
  padding-left: 15px;
  color: #fff;
  margin: 0;
}

#navbar {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: transparent;
  width: 100%;
}

#menu {
  list-style-type: none;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin: -5px;
    padding: 0;
    /* margin-bottom: -24px; */
    margin-top: 30px;
}

a {
  padding: 20px;
}

li:hover {
  background-color: #42d392;
}

a {
  text-decoration: none;
  font-size: 18px;
  color: #fff !important;
  white-space: nowrap;
}

.fa {
  display: none;
  color: #fff;
  cursor: pointer;
}
@media screen and (max-width: 430px) {
  .content {
    text-align: center;
    font: normal normal bold 16px/40px Italic;
    letter-spacing: 1.16px;
    color: #ffffff;
    opacity: 1;
}
  .content h1[data-v-619d7242] {
    text-align: center;
    /* font: normal normal bold 16px/40px Poppins; */
    letter-spacing: 1.16px;
    color: #ffffff;
    opacity: 1;
}
  .card3 {
    display: none !important;
  }
  .card {
    display: flex;
    justify-content: space-around;
    width: 150px;
    height: 300px;
  }

  .card img {
    width: 120px;
    height: 105px;
  }

  .name h3 {
    font-size: 13px;
  }
  .name p {
    font-size: 11px;
  }

  .price p {
    font-size: 15px;
    text-align: end;
  }

  .price span {
    font-size: 12px;
  }

  .addToCartButton {
    width: 90px;
  }

  .content p {
    text-align: center;
  }
  ul {
    text-align: center;
    background-color: black;
  }
  .nav {
    /* display: flex; */
  }
}

@media screen and (max-width: 415px) {
  .content {
    text-align: center;
    font: normal normal bold 15px/40px Italic;
    letter-spacing: 1.16px;
    color: #ffffff;
    opacity: 1;
}
  .content h1[data-v-619d7242] {
    text-align: center;
    /* font: normal normal bold 15px/40px Poppins; */
    letter-spacing: 1.16px;
    color: #ffffff;
    opacity: 1;
}
  .card3 {
    display: none !important;
  }
  .card {
    display: flex;
    justify-content: space-around;
    width: 150px;
    height: 300px;
  }

  .card img {
    width: 120px;
    height: 105px;
  }

  .name h3 {
    font-size: 13px;
  }
  .name p {
    font-size: 11px;
  }

  .price p {
    font-size: 15px;
    text-align: end;
  }

  .price span {
    font-size: 12px;
  }

  .addToCartButton {
    width: 90px;
  }

  .content p {
    text-align: center;
  }
  ul {
    text-align: center;
    background-color: black;
  }
}
@media screen and (max-width: 390px) {
  .content {
    text-align: center;
    font: normal normal bold 11px/40px Italic;
    letter-spacing: 1.16px;
    color: #ffffff;
    opacity: 1;
}
  .content h1[data-v-619d7242] {
    text-align: center;
    /* font: normal normal bold 11px/40px Poppins; */
    letter-spacing: 1.16px;
    color: #ffffff;
    opacity: 1;
}
  .card3 {
    display: none !important;
  }
  .card {
    display: flex;
    justify-content: space-around;
    width: 150px;
    height: 300px;
  }

  .card img {
    width: 120px;
    height: 105px;
  }

  .name h3 {
    font-size: 13px;
  }
  .name p {
    font-size: 11px;
  }

  .price p {
    font-size: 15px;
    text-align: end;
  }

  .price span {
    font-size: 12px;
  }

  .addToCartButton {
    width: 90px;
  }

  .content p {
    text-align: center;
  }
}


</style>

