<template>
  <div class="testimonials">
    <div class="container" data-aos="zoom-in" data-aos-duration="1500" >
      <h1 data-aos="zoom-in" data-aos-duration="1500" >{{$t("FeedBack")}}</h1>
      <div class="clientsContainer">
        <div class="clients">
          <div class="slide">
            <div class="ancestor" id="box-1">
              <div class="slide_text">
                <p id="tanya_text" class="text" style="margin-top: 100px;" v-if="!isHidden" data-aos="zoom-in" data-aos-duration="1500" >
                  {{$t("FeedBack2")}}
                </p>
                <p id="john_text" class="text" style="margin-bottom: 10px;" v-else data-aos="zoom-in" data-aos-duration="1500">
                  {{$t("FeedBack2")}}
                </p>
                <p class="name" v-if="!isHidden" data-aos="zoom-in" data-aos-duration="1500">
                  {{$t("FeedBack1_p")}}<span class="profession"></span>
                </p>
                <p class="name" v-else>
                  {{$t("FeedBack1_p")}}
                  <span class="profession"></span>
                </p>
              </div>
              <div class="portrait">
                <!-- <img
                  class="portrait_img"
                  :src="images[Math.abs(currentNumber) % images.length]"
                  alt="portrait_img"
                /> -->
              </div>
              <!-- <div class="nav">
                <span @click="prev(), (isHidden = !isHidden)"
                  ><img src="../../assets/images/icon-prev.svg" class="nav-left" alt="prev-img"
                /></span>
                <span @click="next(), (isHidden = !isHidden)"
                  ><img src="../../assets/images/icon-next.svg" class="nav-right" alt="next-img"
                /></span>
              </div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="container ceo">
        <div class="quotes">
          <div>
            <h1 data-aos="zoom-in" data-aos-duration="1500">{{$t("OurTeam")}} </h1>
            <!-- <h1 class="hash">{{$t("OurTeam_hash")}} </h1> -->
          </div>
          <p data-aos="zoom-in" data-aos-duration="1500">
            {{$t("OurTeam_1")}}
          </p>
        </div>
<!--        <div class="container ceo_slider" style="padding-left: 0px; padding-right: 0px;" data-aos="zoom-in" data-aos-duration="1500">-->
<!--          &lt;!&ndash; <TeamSlider /> &ndash;&gt;-->
<!--          <VueSlickCarousel v-bind="settings" class="teamSlider">-->
<!--            <div style="width: 100%">-->
<!--              <img class="img" src="../../assets/thebest team 4.jpg" alt="dasdas" />-->
<!--            </div>-->
<!--          </VueSlickCarousel>-->
<!--        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
import photo1 from "../../assets/images/image-john-removebg-preview.png";
import photo2 from "../../assets/images/image-tanya-removebg-preview.png";
import TeamSlider from "../Home/TeamSlider.vue";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  components: { VueSlickCarousel },
  data() {
    return {
      isHidden: false,
      images: [photo1, photo2],
      currentNumber: 0,
      settings: {
        dots: false,
        dotsClass: "slick-dots custom-dot-class",
        edgeFriction: 0.35,
        arrows: true,
        infinite: true,
        autoplay: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,

        responsive: [
        {
            breakpoint: 1224,

            settings: {
              slidesToShow: 2,

              slidesToScroll: 1,

              infinite: true,

              dots: false,
            },
          },
          {
            breakpoint: 1024,

            settings: {
              slidesToShow: 2,

              slidesToScroll: 1,

              infinite: true,

              dots: false,
            },
          },
          {
            breakpoint: 900,

            settings: {
              slidesToShow: 1,

              slidesToScroll: 1,

              infinite: true,
              arrows:false,
              dots: false,
            },
          },
          {
            breakpoint: 390,

            settings: {
              slidesToShow: 1,

              slidesToScroll: 1,

              infinite: true,

              dots: false,
              arrows: false,
            },
          },

          {
            breakpoint: 600,

            settings: {
              slidesToShow: 1,

              slidesToScroll: 1,

              initialSlide: 2,
              arrows: false,
              dots: false,
            },
          },

          {
            breakpoint: 500,

            settings: {
              slidesToShow: 1,

              slidesToScroll: 1,
              arrows: false,
              dots: false,
            },
          },
          {
            breakpoint: 768,

            settings: {
              slidesToShow: 1,

              slidesToScroll: 1,
              arrows: false,
            },
          },
        ],
      },
    };
  },
  methods: {
    next: function () {
      this.currentNumber += 1;
    },
    prev: function () {
      this.currentNumber -= 1;
    },
  },
};
</script>

<style scoped>
/* // @import "./../../assets/style/testimonials.scss"; */
.button.slick-next:before {
  color: blue !important;
}

body {
  font-family: "Poppins", Helvetica, "san-serif";
}

.photos {
  width: 282px;
  height: 185px;
  /* UI Properties */
  border-radius: 12px;
  opacity: 1;
}
.ceo_slider {
   /* margin-top: 30px; */
  width: 70%;
   /* height: 150px !important; */
  /* margin-left: 150px; */
  margin-top: 20px;
}

.ancestor {
  display: grid;
  grid-template-columns: 2fr 1fr 2fr;
  grid-template-rows: auto;
  place-items: center;
  position: relative;
  padding: 30px;
  display: flex;
  flex-direction: row-reverse;
}

.teamSlider {
  width: 100%;
  height: 200px;
  /* margin-left: 118px; */
}
.teamSlider div img {
  width: 98%;
  /* height: 200px; */
   /* padding-left: 20px; */
   border-radius: 10px;
}
.slide {
    /* background-image: url("../../assets/images/pattern-bg.svg"),
      url("../../assets/images/pattern-curve.svg"); */
    background-position: top, left bottom;
    background-repeat: no-repeat;
    background-size: 100px;
  }
.text {
  /* width: 630px;
  height: 114px; */
  /* text-align: left; */
  font: normal normal normal 24px/41px Poppins;
  letter-spacing: -0.72px;
  color: #141414;
  opacity: 1;
  width: 80%;
  text-align: center;
  margin-left: 5%;
}



.slide_text {
  /* background-image: url("../../assets/images/pattern-quotes.svg"); */
  background-position: left top;
  background-repeat: no-repeat;
  grid-column: 1 / 3;
  position: relative;
  left: 10px;
}

#john_text,
#tanya_text {
  /* font-size: 2rem;
  margin-bottom: 20px; */
  /* text-align: left; */
  font: normal normal normal 24px/41px Poppins;
  letter-spacing: -0.72px;
  color: #141414;
  opacity: 1;
  width: 90%;
}

.name,
.profession {
  text-align: center;
  font: normal normal bold 18px/38px Poppins;
  letter-spacing: 0px;
  color: #141414;
  opacity: 1;
}

.profession {
  color: hsl(240, 18%, 77%);
}

.portrait {
  position: relative;
  top: -60px;
}

.portrait_img {
  /* -webkit-box-shadow: 9px 10px 32px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 9px 10px 32px 0px rgba(0, 0, 0, 0.75); */
  /* box-shadow: 9px 10px 32px 0px rgba(0, 0, 0, 0.75); */
  /* width: 370px; */
  width: 350px;
  border-radius: 5%;
  /* height: 476px; */
}

.images {
  grid-column: 2 /4;
}

.nav {
  grid-column: 3 / 4;
  background-color: rgb(255, 255, 255);
  color: var(--dark-blue);
  padding: 1em;
  position: absolute;
  top: 70%;
  left: 11%;
  border-radius: 2rem;
  border: none;
  z-index: 30;
  cursor: pointer;
  padding: 1rem;
}

.nav-left,
.nav-right {
  padding: 0 20px 0 20px;
}

.slider-prev {
  padding-right: 1.5em;
}

.slider-next {
  padding-left: 1.5em;
}

.attribution {
  font-size: 11px;
  text-align: center;
}

.attribution a {
  color: hsl(228, 45%, 44%);
}

/* Small devices (tablets, 600px and up) */
@media only screen and (max-width: 900px) {

  .ancestor {
    display: grid;
    grid-template-columns: 2fr;
    grid-template-rows: auto auto;
    place-items: center;
    padding: 0;
  }

  .portrait_img {
    width: 200px;
    margin-top: 10px;
  }

  .slide {
    /* background-image: url("../../assets/images/pattern-bg.svg"),
      url("../../assets/images/pattern-curve.svg"); */
    background-position: top, left bottom;
    background-repeat: no-repeat;
    background-size: 100px;
  }

  .slide_text {
    /* background-image: url("../../assets/images/pattern-quotes.svg"); */
    background-position: center;
    background-repeat: no-repeat;
    grid-column: 1 / 2;
    grid-row: 3/4;
    position: relative;
    left: 0;
    text-align: center;
  }

  #john_text,
  #tanya_text {
    font-size: 2rem;
    margin-bottom: 0;
    width: 90%;
  }

  .name,
  .profession {
    font-weight: 700;
  }

  .profession {
    color: hsl(240, 18%, 77%);
  }

  .images {
    grid-column: 1 /2;
    grid-row: 1/2;
  }

  .portrait_photo {
    box-shadow: 18px 10px 36px 1px rgba(0, 0, 0, 0.75);
  }

  .nav {
    grid-column: 1 / 2;
    grid-row: 2/3;
    background-color: rgb(255, 255, 255);
    color: var(--dark-blue);
    padding: 1em;
    border-radius: 2rem;
    border: none;
    z-index: 30;
    cursor: pointer;
    padding: 1rem;
    position: relative;
    top: -26px;
    left: 0;
  }

  .nav-left,
  .nav-right {
    padding: 0 20px 0 20px;
  }

  .slider-prev {
    padding-right: 1.5em;
  }

  .slider-next {
    padding-left: 1.5em;
  }

  .attribution {
    font-size: 11px;
    text-align: center;
  }

  .attribution a {
    color: hsl(228, 45%, 44%);
  }
}

@media only screen and (max-width: 600px) {
  body {
    width: 100%;
  }

  .ancestor {
    display: grid;
    grid-template-columns: 2fr 1fr 2fr;
    grid-template-rows: auto auto;
    place-items: center;
  }

  .slide {
    /* background-image: url("../../assets/images/pattern-bg.svg"),
      url("../../assets/images/pattern-curve.svg"); */
    background-position: center top, left bottom, center;
    background-repeat: no-repeat;
  }

  .slide_text {
    /* background-image: url("../../assets/images/pattern-quotes.svg");
    background-image: url("../../assets/images/pattern-quotes.svg"); */
    background-position: left top;
    background-repeat: no-repeat;
    grid-column: 1 / 2;
    grid-row: 3/4;
  }

  #john_text,
  #tanya_text {
    font-size: 2rem;
    margin-bottom: 20px;
    width: 90%;
  }

  .name,
  .profession {
    font-weight: 700;
  }

  .profession {
    color: hsl(240, 18%, 77%);
  }

  .images {
    grid-column: 1 /2;
    grid-row: 1/2;
  }

  .portrait_photo {
    box-shadow: 18px 10px 36px 1px rgba(0, 0, 0, 0.75);
    box-shadow: -18px 10px 36px 1px rgba(0, 0, 0, 0.75);
    box-shadow: 18px 10px 36px 1px rgba(0, 0, 0, 0.75);
  }

  .nav {
    grid-column: 1 / 2;
    grid-row: 2/3;
    background-color: rgb(255, 255, 255);
    color: var(--dark-blue);
    padding: 1em;
    border-radius: 2rem;
    border: none;
    z-index: 30;
    cursor: pointer;
    padding: 1rem;
  }

  .nav-left,
  .nav-right {
    padding: 0 20px 0 20px;
  }

  .slider-prev {
    padding-right: 1.5em;
  }

  .slider-next {
    padding-left: 1.5em;
  }

  .attribution {
    font-size: 11px;
    text-align: center;
  }

  .attribution a {
    color: hsl(228, 45%, 44%);
  }
}
.testimonials {
  background: transparent linear-gradient(118deg, #141414 0%, #112535 14%, #141414 100%) 0% 0%
    no-repeat padding-box;
  padding-top: 61px;
  width: 100%;
  min-height: 1005px;
  width: 100%;
}

h1 {
  /* width: 495px;
    height: 46px; */
  /* UI Properties */
  font: normal normal bold 34px/46px Poppins;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  text-align: center;
}
.clients {
  height: 410px;
  /* UI Properties */
  background: transparent linear-gradient(108deg, #ffffff 0%, #fefefe 0%, #ffffff 100%) 0% 0%
    no-repeat padding-box;
  border-radius: 30px;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-content: center;
  margin-top: 3%;
}
.ceo {
  /* background-color: #fefefe; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 100px;
}

.quotes {
  width: 350px;
}

.quotes h1 {
  text-align: left;
  font: normal normal bold 30px/47px Poppins;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.quotes p {
  text-align: left;
  font: normal normal normal 12px/15px Poppins;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

@media screen and (max-width: 1400px) {
  .testimonials {
  background: transparent linear-gradient(118deg, #141414 0%, #112535 14%, #141414 100%) 0% 0%
    no-repeat padding-box;
  padding-top: 61px;
  width: 100%;
  min-height: 1110px;
  width: 100%;
}
  .slide {
    /* background-image: url("../../assets/images/pattern-bg.svg"),
      url("../../assets/images/pattern-curve.svg"); */
    background-position: top, left bottom;
    background-repeat: no-repeat;
    background-size: 120px;
  }
  .ceo {
    display: flex;
    flex-direction: column;
    margin-top: 33px;
  }
  .quotes {
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .quotes h1{
    text-align: center;
  }

  .quotes p {
    width: 100%;
    text-align: center;
  }

  .ceo_slider {
    width: 100%;
  }
}
@media screen and (max-width: 992px) {
  .slide {
    /* background-image: url("../../assets/images/pattern-bg.svg"),
      url("../../assets/images/pattern-curve.svg"); */
    background-position: top, left bottom;
    background-repeat: no-repeat;
    background-size: 100px;
  }
  .ceo {
    display: flex;
    flex-direction: column;
    margin-top: 33px;
  }
  .quotes {
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .quotes p {
    width: 100%;
  }
  .quotes h1{
    text-align: center;
  }

  .ceo_slider {
    width: 100%;
  }

  .img {
    /* height: 100px; */
    width: 200px;
  }
  #john_text,
  #tanya_text {
    font-size: 17px;
    width: 90%;
  }
}
@media screen and (max-width: 768px) {
  .teamSlider div img[data-v-f251ddf8] {
    width: 100%;
    /* height: 205px; */
    /* padding-left: 20px; */
    border-radius: 10px;
}
  .ceo {
    display: flex;
    flex-direction: column;
    margin-top: 33px;
  }
  .quotes {
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .quotes p {
    width: 100%;
  }
  .quotes h1{
    text-align: center;
  }

  .ceo_slider {
    width: 100%;
  }

  .img {
    /* height: 60px; */
    width: 150px;
  }
}
@media screen and (max-width: 900px) {
  .testimonials {
  background: transparent linear-gradient(118deg, #141414 0%, #112535 14%, #141414 100%) 0% 0%
    no-repeat padding-box;
  padding-top: 61px;
  width: 100%;
  min-height: 1200px;
  width: 100%;
}
  .teamSlider div img[data-v-f251ddf8] {
    width: 100%;
    /* height: 200px; */
    /* padding-left: 20px; */
    border-radius: 10px;
}
  .ceo {
    display: flex;
    flex-direction: column;
    margin-top: 33px;
  }
  .quotes {
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .quotes p {
    width: 100%;
  }

  .quotes h1{
    text-align: center;
  }

  .ceo_slider {
    width: 100%;
  }

  .img {
    /* height: 60px; */
    width: 150px;
  }
  .portrait_img {
    margin-top: 25%;
  }
  .nav {
    margin-top: -70px;
  }

  .text {
    margin-top: -35px;
  }
}
@media screen and (max-width: 767px) {
  .testimonials {
  background: transparent linear-gradient(118deg, #141414 0%, #112535 14%, #141414 100%) 0% 0%
    no-repeat padding-box;
  padding-top: 61px;
  width: 100%;
  min-height: 1150px;
  width: 100%;
}
  .ceo {
    display: flex;
    flex-direction: column;
    margin-top: 33px;
  }
  .quotes {
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .quotes p {
    width: 100%;
  }
  .quotes h1{
    text-align: center;
  }

  .ceo_slider {
    width: 100%;
  }

  .img {
    /* height: 60px; */
    width: 150px;
  }
  .portrait_img {
    margin-top: 25%;
  }
  .nav {
    margin-top: -70px;
  }

  .text {
    margin-top: -35px;
  }
  #john_text,
  #tanya_text {
    font-size: 15px;
    width: 90%;
  }
}
@media screen and (max-width: 600px) {
  .testimonials {
  background: transparent linear-gradient(118deg, #141414 0%, #112535 14%, #141414 100%) 0% 0%
    no-repeat padding-box;
  padding-top: 61px;
  width: 100%;
  min-height: 1150px;
  width: 100%;
}
  .ceo {
    display: flex;
    flex-direction: column;
    margin-top: 33px;
  }
  .quotes {
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: space-around;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
  }

  .quotes p {
    width: 100%;
  }
  .quotes h1{
    text-align: center;
  }
  .quotes h1 {
    font-size: 25px;
  }

  .slick-next:before {
    color: black !important;
    font-size: 10px;
    padding-top: 5px;
    padding-right: 3px;
    padding-left: 3px;
    border-radius: 20px;
    background-color: white;
  }

  .ceo_slider {
    width: 100%;
  }

  .img {
    /* height: 60px; */
    width: 150px;
  }
  .portrait_img {
    margin-top: 25%;
  }
  .nav {
    margin-top: -70px;
    position: absolute;
    top: 54%;
    left: 35%;
  }

  .text {
    margin-top: -35px;
  }
  #john_text,
  #tanya_text {
    font-size: 15px;
    width: 90%;
  }
  .ancestor {
    display: flex;
    flex-direction: column-reverse;
  }

  .name {
    margin-top: -20px;
  }
}
@media screen and (max-width: 500px) {
  #john_text[data-v-f251ddf8],
  #tanya_text[data-v-f251ddf8] {
    font-size: 15px;
    width: 90%;
  }
  .teamSlider div img[data-v-f251ddf8][data-v-f251ddf8] {
    width: 100%;
    /* height: 205px; */
    /* padding-left: 20px; */
    border-radius: 10px;
}
  .hash {
    margin-top: -15px;
  }
  .name,
  .profession {
    text-align: center;
    font: normal normal bold 13px/36px Poppins;
    letter-spacing: 0px;
    color: #141414;
    opacity: 1;
  }
  .quotes h1 {
    font-size: 20px;
  }
}
@media screen and (max-width: 514px) {
  .testimonials[data-v-f251ddf8] {
    background: transparent linear-gradient(118deg, #141414 0%, #112535 14%, #141414 100%) 0% 0% no-repeat padding-box;
    padding-top: 61px;
    width: 100%;
    min-height: 1100px;
    width: 100%;
  }
  .ceo {
    display: flex;
    flex-direction: column;
    margin-top: 55px;
  }
  .quotes {
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-top: -41px;
  }

  .quotes p {
    width: 100%;
  }

  .quotes h1 {
    font-size: 20px;
  }

  .ceo_slider {
    width: 100%;
  }

  .img {
    /* height: 60px; */
    width: 150px;
  }
  .portrait_img {
    margin-top: 25%;
  }
  .nav {
    margin-top: -70px;
    position: absolute;
    top: 54%;
    left: 35%;
  }

  .text {
    width: 85%;
  }
  #john_text,
  #tanya_text {
    font-size: 13px;
    width: 90%;
  }
  .ancestor {
    display: flex;
    flex-direction: column-reverse;
  }

  .name {
    margin-top: -20px;
    font-size: 17px;
  }
}




@media screen and (max-width: 430px) {
  #john_text[data-v-f251ddf8],
  #tanya_text[data-v-f251ddf8] {
    font-size: 13px;
    width: 90%;
  }
  .hash {
    margin-top: -15px;
  }
  .name,
  .profession {
    text-align: center;
    font: normal normal bold 13px/36px Poppins;
    letter-spacing: 0px;
    color: #141414;
    opacity: 1;
  }
  .ceo[data-v-f251ddf8] {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
}
}
@media screen and (max-width: 415px) {
  #john_text[data-v-f251ddf8],
  #tanya_text[data-v-f251ddf8] {
    font-size: 13px;
    width: 90%;
  }
  .hash {
    margin-top: -15px;
  }
  .name,
  .profession {
    text-align: center;
    font: normal normal bold 13px/36px Poppins;
    letter-spacing: 0px;
    color: #141414;
    opacity: 1;
  }
  .ceo[data-v-f251ddf8] {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
}
}
@media screen and (max-width: 390px) {
  #john_text[data-v-f251ddf8],
  #tanya_text[data-v-f251ddf8] {
    font-size: 12px;
    width: 90%;
  }
  .name,
  .profession {
    text-align: center;
    font: normal normal bold 13px/36px Poppins;
    letter-spacing: 0px;
    color: #141414;
    opacity: 1;
  }
  .ceo[data-v-f251ddf8] {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
}
}
@media screen and (max-width: 330px) {
  #john_text[data-v-f251ddf8],
  #tanya_text[data-v-f251ddf8] {
    font-size: 12px;
    width: 90%;
  }
  .name,
  .profession {
    text-align: center;
    font: normal normal bold 13px/0px Poppins;
    letter-spacing: 0px;
    color: #141414;
    opacity: 1;
  }
  .testimonials[data-v-f251ddf8] {
    background: transparent linear-gradient(118deg, #141414 0%, #112535 14%, #141414 100%) 0% 0% no-repeat padding-box;
    padding-top: 61px;
    width: 100%;
    min-height: 1100px;
    width: 100%;
}
.ceo[data-v-f251ddf8] {
    display: flex;
    flex-direction: column;
    margin-top: 76px;
}
}
</style>
