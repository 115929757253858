<template>
  <div>
    <NavbarContactVue />
    <ContactForm />
    <div id="appp" v-cloak>
      <transition name="fade">
        <div
          id="pagetop"
          class="fixed right-0 bottom-0"
          v-show="scY > 300"
          @click="toTop"
        >
          <svg
            style="
              background-color: grey;
              border-radius: 10px;
              cursor: pointer;
            "
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            height="48"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#fff"
            stroke-width="1"
            stroke-linecap="square"
            stroke-linejoin="arcs"
          >
            <path d="M18 15l-6-6-6 6" />
          </svg>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import NavbarContactVue from "../components/Contact/NavbarContact.vue";
import ContactForm from "../components/Contact/ContactForm.vue";
export default {
  components: { NavbarContactVue, ContactForm },
  data() {
    return {
      scTimer: 0,
      scY: 0,
    };
  },

  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll: function () {
      if (this.scTimer) return;
      this.scTimer = setTimeout(() => {
        this.scY = window.scrollY;
        clearTimeout(this.scTimer);
        this.scTimer = 0;
      }, 100);
    },
    toTop: function () {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>
<style scoped>
#appp {
  position: fixed;
  bottom: 5px;
  right: 5px;
  z-index: 1;
}
</style>
